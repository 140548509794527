export class Acabado {

    acabado = '';


    descripcion = '';


    grupoVariante = '';


    tratVariantes = '';
}

