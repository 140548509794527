/**
 * Contains definitions and data related to WinkHaus Oscilo 2 tipology
 */
export class WinkHausOscilo2 {

    /** List of available heights for the tipology */
    heightSizes = ["606-895", "896-1145", "1146-1495", "1496-1655", "1656-1895", "1896-2395"];


    /** List of available widths for the tipology */
    widthSizes = ["680-1340", "1341-1740", "1741-2190", "2191-2640", "2641-3090"];


    /** List of values that match each height/width for the right hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    rightValues = ["HM760808+HM960809", "HM780808+HM960809", "HM710808+HM960809", "HM712808+HM960809", "HM714808+HM960809", "HM760108+HM960109", "HM780108+HM960109", "HM710108+HM960109", "HM712108+HM960109", "HM714108+HM960109", "HM760148+HM960149", "HM780148+HM960149", "HM710148+HM960149", "HM712148+HM960149", "HM714148+HM960149", "HM760158+HM960159", "HM780158+HM960159", "HM710158+HM960159", "HM712158+HM960159", "HM714158+HM960159", "HM760188+HM960189", "HM780188+HM960189", "HM710188+HM960189", "HM712188+HM960189", "HM714188+HM960189", "HM760238+HM960239", "HM780238+HM960239", "HM710238+HM960239", "HM712238+HM960239", "HM714238+HM960239"];


    /** List of values that match each height/width for the left hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    leftValues = ["HM760809+HM960808", "HM780809+HM960808", "HM710809+HM960808", "HM712809+HM960808", "HM714809+HM960808", "HM760109+HM960108", "HM780109+HM960108", "HM710109+HM960108", "HM712109+HM960108", "HM714109+HM960108", "HM760149+HM960148", "HM780149+HM960148", "HM710149+HM960148", "HM712149+HM960148", "HM714149+HM960148", "HM760159+HM960158", "HM780159+HM960158", "HM710159+HM960158", "HM712159+HM960158", "HM714159+HM960158", "HM760189+HM960188", "HM780189+HM960188", "HM710189+HM960188", "HM712189+HM960188", "HM714189+HM960188", "HM760239+HM960238", "HM780239+HM960238", "HM710239+HM960238", "HM712239+HM960238", "HM714239+HM960238"];


    /** list of codes for finishes products that must be added to the final product line when right hand is selected */
    rightAdd = ["HE3638XX", "CI4004XX", "HE3639XX"];


    /** list of codes for finishes products that must be added to the final product line when left hand is selected */
    leftAdd = ["HE3638XX", "CI4004XX", "HE3639XX"];
}
