/**
 * Ruta entity
 */
export class Ruta {


    codigoTransporte = '';


    localidad = '';


    chofer = '';


    periodicidad = '';
}
