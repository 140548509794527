import { Injectable } from '@angular/core';
import { LocalesService, DialogService, DialogSingleSelectionListComponent, SingletoneStrictClass, TurboApiCallerService } from 'turbogui-angular';


/**
 * Service related to customer operations
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerService extends SingletoneStrictClass{


    constructor(private readonly ls: LocalesService,
                private readonly dialogService: DialogService,
                private readonly apiService: TurboApiCallerService) {

        super(CustomerService);
    }

    
    /**
     * Search for a customer from a list containing all that are related to the currently loged user
     */
    selectCustomer(responseCallback: (codigo: string, nombre: string) => void) {

        this.apiService.call('customers/customers-list').then(response => {
            
            this.dialogService.addDialog(
                DialogSingleSelectionListComponent,
                {
                    width:'800px',
                    modal: false,
                    texts: [this.ls.t('SELECT_A_CUSTOMER', 'turbodepot/shopping'), '', this.ls.t('SEARCH', 'turbodepot/user-interface')],
                    options: response.map((a:any) => a.codigo + ' - ' + a.nombre)
                },
                (selection:any) =>{
                
                    if(selection.index >= 0){
                    
                        responseCallback(response[selection.index].codigo, response[selection.index].nombre);
                    
                    }else{
                        
                        responseCallback('', '');
                    }
                }); 
        });
    }
}
