import { Injectable } from '@angular/core';


/**
 * Model containing all the application global state values
 */
@Injectable({
  providedIn: 'root',
})
export class AppModel {


    /**
     * used to notify the view change to the orders options view
     */
    static readonly SHOW_ORDERS_OPTIONS = 'SHOW_ORDERS_OPTIONS';
    

    /**
     * The main title that is shown by the application
     */
    mainTitle = '';
}
