import { Product } from './Product';
import { UnidadMedida } from './UnidadMedida';
import { Acabado } from './Acabado';
import { Variante } from './Variante';
import { Descuento } from './Descuento';
import { Familia } from './Familia';
import { SubFamilia } from './SubFamilia';
import { Accesorio } from './Accesorio';


export class OrderLine {


    name = '';


    family = new Familia();


    subFamily = new SubFamilia();


    product:Product = new Product();


    finish:Acabado = new Acabado();


    variant:Variante = new Variante();


    unidadesMedida = new UnidadMedida();


    discount = new Descuento();


    isDiscountManuallyModified = '';


    accesories:Accesorio[] = [];


    relatedOrderLine = '';


    units = 0;
    
    
    unitPrice = -1;


    /** This value only applies to orders that are sent as incidences, and stores the state (bien or mal) */
    incidenceState = '';


    /** This value only applies to orders that are sent as incidences, and stores the cause of the incidence */
    incidenceCause = '';


    /** This value only applies to orders that are sent as incidences, and stores the observations of the incidence */
    incidenceObservations = '';
}
