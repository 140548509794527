/**
 * Contains definitions and data related to WinkHaus Pract 1 tipology
 */
export class WinkHausPract1 {

    /** List of available heights for the tipology */
    heightSizes = ["515-605", "606-895", "896-1145", "1146-1495", "1496-1655", "1656-1895", "1896-2395"];


    /** List of available widths for the tipology */
    widthSizes = [];


    /** List of values that match each height/width for the right hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    rightValues = ["HM860518", "HM860808", "HM860108", "HM860148", "HM860158", "HM860188", "HM860238"];


    /** List of values that match each height/width for the left hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    leftValues = ["HM860519", "HM860809", "HM860109", "HM860149", "HM860159", "HM860189", "HM860239"];


    /** list of codes for finishes products that must be added to the final product line when right hand is selected */
    rightAdd = ["HE3638XX", "CI4004XX"];


    /** list of codes for finishes products that must be added to the final product line when left hand is selected */
    leftAdd = ["HE3639XX", "CI4004XX"];
}
