import { Injectable } from '@angular/core';
import { ViewsService, TurboApiCallerService, DialogService, ViewService } from 'turbogui-angular';
import { OrderDetailsComponent } from './order-details.component';


/**
 * The service for the orders list view
 */
@Injectable()
export class OrdersWebListViewService extends ViewService{


    /**
     * List of order items that have been loaded for the current user
     */
    itemsList: any[] = [];
    
    
    /**
     * Data for the order that is being currently shown
     */
    currentOrderData: any = null;


    constructor(public viewsService: ViewsService,
                public dialogService: DialogService,
                public apiService: TurboApiCallerService) {
                    
        super();
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadList() {

        this.itemsList = [];
        
        this.apiService.call('orders/list-orders')
            .then(response => {
            
            this.itemsList = response;
        });
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadOrderData(orderNumber:string) {

        this.currentOrderData = null;
        
        this.apiService.call('orders/get-order-details', {orderNumber: orderNumber})
            .then(response => {
            
            this.currentOrderData = response;
            
            this.dialogService.addDialog(OrderDetailsComponent, {width: '1500px', modal: false, viewContainerRef: this.viewContainerRef});
        });
    }
}
