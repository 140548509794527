<h3>{{
    this.expansionRoutesViewService.isEditingRoute ?
        ls.t('EDIT_ROUTE', 'centroalum/app') :
        ls.t('CREATE_NEW_ROUTE', 'centroalum/app')
    }}
</h3>

<mat-form-field>
    <mat-label>{{ls.t('NAME', 'turbodepot/personal-data')}}</mat-label>
    <input id="routeNameInput" matInput autoFocusOnDisplay autoSelectTextOnFocus
        [(ngModel)]="expansionRoutesViewService.routeBeingCurrentlyEdited.name">
</mat-form-field>

<mat-form-field>
    
    <mat-label>{{ls.t('CUSTOMERS', 'turbodepot/shopping')}}</mat-label>
    
    <textarea id="routeCustomersInput" matInput autoSelectTextOnFocus
        [(ngModel)]="expansionRoutesViewService.routeBeingCurrentlyEdited.customers">
    </textarea>
       
</mat-form-field>

<div class="submitButtonsContainer">
    
   <button mat-button
        id="cancelRouteButton"
        (click)="closeDialog(0)">
        
        {{this.ls.t('CANCEL', 'turbodepot/user-interface')}}
        
    </button>
    
     <button mat-raised-button color="primary"
        id="saveRouteButton"
        [disabled]="isSaveButtonDisabled()"
        (click)="saveRoute()">
        
        {{this.ls.t('SAVE', 'turbodepot/user-interface')}}
        
    </button>
    
</div>