import { Customer } from './Customer';
import { OrderLine } from './OrderLine';


/**
 * Order entity
 */
export class Order {

    customer:Customer = new Customer();
    
    isUserACustomer = false;
    
    /** If the user marks this order as an offer, this flag will be true, and treated differently at the server side */
    isOrderAnOffer = false;
    
    userRelatedComercial = '';

    orderLines:OrderLine[] = [];

    orderDate = '';

    shipmentDate = '';
    
    shippingAddress = '';

    registroIndirecto:number = -1;

    uploaded:number = -1;

    referenciaPedido = '';
    
    comment = '';

    orderType = '';

    /** This value only applies to orders that are sent as incidences, and stores if the incidence generates devolution or not */
    incidenceGeneratesDevolution = "";

    /** This value only applies to orders that are sent as incidences, and stores the incidence state */
    incidenceState = "";
    
    total = '0';
}
