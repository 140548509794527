/**
 * Product entity
 */
export class Product {

    codigo = '';

    descripcion = '';

    descripcion2 = '';

    familia = '';

    subFamilia = '';

    acabado = '';

    unidadDeVenta = '';

    empaquetado = '';

    perfil = '';

    tratamientoBruto = '';

    unidadesStock = '';
}
