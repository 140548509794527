<h2>{{ls.t('ORDERS_LIST', 'turbodepot/shopping') + ' ' + ordersHistoryViewService.currentCustomerNombre}}</h2>
<tg-button-container id="selectCustomerButton"
    [releaseOnMouseUp]="false"
    [defaultOpacity]=".7"
    [hoverOpacity]=".8"
    [clickOpacity]="1"
    [clickScale]="1.07"
    [disabledOpacity]=".7"
    *ngIf="!userModel.isCustomer"
    (click)="ordersHistoryViewService.selectCustomer()">
    
    <h3>{{ls.t('SELECT_A_CUSTOMER', 'turbodepot/shopping')}}</h3>
        
</tg-button-container>

<div class="notAvailableContainer" *ngIf="ordersHistoryViewService.itemsList.length === 0">
    <h3>{{ls.t('NOT_AVAILABLE', 'turbodepot/shopping')}}</h3>
</div>

<div class="ordersContainer" *ngIf="ordersHistoryViewService.itemsList.length > 0">
    
    <div>
        <h4>{{ls.t('ORDER_NUMBER', 'turbodepot/shopping')}}</h4>
        <h4>{{ls.t('ORDER_DATE', 'turbodepot/shopping')}}</h4>
        <h4>{{ls.t('ESTIMATE_DELIVERY_DATE', 'centroalum/app')}}</h4>
    </div>
    
    
    <tg-button-container *ngFor="let item of ordersHistoryViewService.itemsList; let i = index"
        id="ordersListItem_{{i}}"
        [@contentFade]
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".8"
        [hoverOpacity]="1"
        [clickScale]="1.04"
        (click)="onOrderClick(item)">
        
        <p *ngFor="let cell of item">{{cell}}</p>
                
    </tg-button-container>

</div>