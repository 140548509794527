/**
 * UnidadMedida entity
 */
export class UnidadMedida {

    producto = '';


    code = '';


    cantidad = '';
}
