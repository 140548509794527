import { Component, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ViewsService, View, LocalesService, DialogService, TurboGuiAngularModule, ButtonContainerComponent } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { AppModel } from 'src/main/model/models/app.model';
import { OrdersHistoryViewService } from './orders-history-view.service';
import { UserModel } from 'src/main/model/models/user.model';
import { CommonModule } from '@angular/common';


/**
 * Orders list view
 */
@Component({
    selector: 'app-orders-history-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent, TurboGuiAngularModule],
    providers: [OrdersHistoryViewService],
    templateUrl: './orders-history-view.component.html',
    animations: [ContentFadeAnimationClass.getTrigger()],
    styleUrls: ['./orders-history-view.component.scss']
})


export class OrdersHistoryViewComponent extends View implements AfterViewInit, OnDestroy {


    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public appModel: AppModel,
                public userModel: UserModel,
                public ordersHistoryViewService: OrdersHistoryViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, ordersHistoryViewService);

        this.appModel.mainTitle = this.ls.t('ORDERS_LIST', 'turbodepot/shopping');
    }


    ngAfterViewInit() {

        if(this.userModel.isCustomer){
            
            this.ordersHistoryViewService.currentCustomer = this.userModel.customer.codigo;
            this.ordersHistoryViewService.loadList();
            
        }else{
            
            this.ordersHistoryViewService.selectCustomer();
        }
    }
    
    
    /**
     * Obtain the data from a single order
     */
    onOrderClick(item: string[]) {

        this.ordersHistoryViewService.loadOrderData(item[0]);
    }
    
    
    ngOnDestroy() {

        this.appModel.mainTitle = '';
    }
}
