<h2>
    <img id="routesBackButton" src="./assets/icons/arrow-back-black.svg"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="closeRouteDetails()"/>
        
    {{ls.t('EXPANSION', 'centroalum/app')}}
    
    <img id="showExpeditionsButton" src="./assets/icons/truck.svg"
            matTooltip="{{ls.t('SHOW_EXPEDITIONS', 'centroalum/app')}}"
            *ngIf="expansionRoutesViewService.isShowingRouteDetails"
            (click)="showExpeditions()"/>
            
    <img id="editRouteButton" src="./assets/icons/edit.svg"
        matTooltip="{{ls.t('EDIT_ROUTE', 'centroalum/app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="editRoute()"/>
        
    <img id="dateFilterButton" src="./assets/icons/calendar.svg"
        matTooltip="{{this.ls.t('FILTER_BY_ESTIMATE_DATE', 'centroalum/app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="selectDateFilterDate()"/>
            
    <img id="deleteRouteButton" src="./assets/icons/delete.svg"
        matTooltip="{{ls.t('DELETE_ROUTE', 'centroalum/app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="deleteRoute()"/>
     
    <img id="addRouteButton" src="./assets/icons/add.svg"
        matTooltip="{{ls.t('CREATE_NEW_ROUTE', 'centroalum/app')}}"
        *ngIf="!expansionRoutesViewService.isShowingRouteDetails"
        (click)="createNewRoute()"/>
    
</h2>
  

<div class="routesContainer"
    *ngIf="!expansionRoutesViewService.isShowingRouteDetails">

    <tg-button-container class="routeContainer" 
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.03"
        *ngFor="let item of expansionRoutesViewService.routesList; let i = index"
        (click)="showRouteDetails(item)">
        
        <img src="./assets/icons/truck.svg" />  
        <h3>{{item.name}}</h3>
        <p>{{item.customers}}</p>
        
    </tg-button-container>
    
</div>

<div class="routeDetailsContainer" [@contentFade]
    *ngIf="expansionRoutesViewService.isShowingRouteDetails">

    <h3>
        {{ls.t('ROUTE_DETAILS', 'centroalum/app') + ': ' + expansionRoutesViewService.routeBeingCurrentlyEdited.name}}
    </h3>
    
    <p id="clearDateFilterButton"
        *ngIf="expansionRoutesViewService.routeDetailsDateFilter !== ''"
        (click)="clearDateFilter()">
        {{this.ls.t('FILTERED_BY_ESTIMATE_DATE', 'centroalum/app') + ': ' + expansionRoutesViewService.routeDetailsDateFilter}}
        
    </p>
    
    <p id="clearExpeditionFilterButton"
        *ngIf="expansionRoutesViewService.routeDetailsExpeditionFilter !== null"
        (click)="clearExpeditionFilter()">
        {{this.ls.t('FILTERED_BY_EXPEDITION', 'centroalum/app') + ': ' + ((expansionRoutesViewService.routeDetailsExpeditionFilter !== null) ? expansionRoutesViewService.routeDetailsExpeditionFilter.name : '')}}
        
    </p>
    
    <div class="headerContainer">
        <h4>{{ls.t('ORDER_NUMBER', 'turbodepot/shopping')}}</h4>
        <h4>{{ls.t('ORDER_DATE', 'turbodepot/shopping')}}</h4>
        <h4>{{ls.t('DELIVERY_DATE', 'centroalum/app')}}</h4>
        <h4 class="hideOnMobile">{{ls.t('ESTIMATE_DATE', 'centroalum/app')}}</h4>
        <h4 class="hideOnMobile">{{ls.t('OBSERVATIONS', 'turbodepot/user-interface')}}</h4>
    </div>
    
    <div class="tableContainer"
        *ngFor="let customerData of expansionRoutesViewService.routeDetails | keyvalue">
        
        <h5>{{ls.t('CUSTOMER', 'turbodepot/shopping') + ': ' + customerData.key + ' - ' + customerData.value.name}}</h5>
        
        <tg-button-container *ngFor="let item of customerData.value.orders; let i = index"
            id="ordersListItem_{{i}}"
            [releaseOnMouseUp]="false"
            [defaultOpacity]=".8"
            [hoverOpacity]="1"
            [clickScale]="1.04"
            [class.hasPendingToReceive]="item[4] === true"
            (click)="expansionRoutesViewService.loadOrderData(item[0], customerData.key, customerData.value.name, item[6])">
            
            <p>{{item[0]}}</p>
            <p>{{item[1]}}</p>
            <p>{{item[2]}}</p>
            <p class="hideOnMobile">{{item[3]}}</p>
            <p class="hideOnMobile">{{item[6]}}</p>
                    
        </tg-button-container>
        
    </div>
        
</div>