import { Component } from '@angular/core';
import { ViewsService, DialogService, LocalesService, FadeAnimationClass, BrowserService, DialogTwoOptionComponent, NotificationService, TurboApiCallerService } from 'turbogui-angular';
import { HomeViewComponent } from '../../../view/views/home-view/home-view.component';
import { ProductsViewComponent } from '../../../view/views/products-view/products-view.component';
import { CatalogsViewComponent } from '../../../view/views/catalogs-view/catalogs-view.component';
import { OrdersMenuViewComponent } from '../../../view/views/orders-menu-view/orders-menu-view.component';
import { AppModel } from 'src/main/model/models/app.model';
import { UserModel } from 'src/main/model/models/user.model';


/**
 * The main component that contains all the application elements
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [FadeAnimationClass.getTrigger('mediumFade', '2s ease', '600ms ease')],
  styleUrls: ['./app.component.scss']
})


export class AppComponent {


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    homeViewComponent = HomeViewComponent;


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    productsViewComponent = ProductsViewComponent;


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    catalogsViewComponent = CatalogsViewComponent;


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    ordersViewComponent = OrdersMenuViewComponent;


    constructor(public viewsService: ViewsService,
                public dialogService: DialogService,
                public ls: LocalesService,
                public apiService: TurboApiCallerService,
                public userModel:UserModel,
                public browserService: BrowserService,
                public appModel: AppModel,
                public notificationService: NotificationService) {
    }
    
    
    logout(){
        
        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.t('CLOSE_SESSION', 'turbodepot/users') + '?', this.ls.t('PLEASE_CONFIRM_TO_CLOSE_SESSION', 'turbodepot/users')],
                options: [this.ls.t('CLOSE_SESSION', 'turbodepot/users'), this.ls.t('CANCEL', 'turbodepot/user-interface')]
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    this.browserService.enableBackButton();
                    this.apiService.logout();
                }
            });
    }
}
