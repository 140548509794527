import { Component, ElementRef } from '@angular/core';
import { LocalesService, DialogBaseComponent, TurboApiCallerService, DelayedMethodCallManager, ViewsService, DialogService,
        DialogMultipleOptionComponent, DialogSingleSelectionListComponent, ButtonContainerComponent, 
        TurboGuiAngularModule} from 'turbogui-angular';
import { MatDialogRef } from '@angular/material/dialog';
import { StringUtils } from 'turbocommons-ts';
import { OrderLine } from 'src/main/model/dtos/OrderLine';
import { UserModel } from 'src/main/model/models/user.model';
import { AccesoriesEditComponent } from './accesories-edit.component';
import { WinkhausComponent } from './winkhaus.component';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { LoadOrderFileComponent } from './load-order-file.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
 

/**
 * Component that lets us add a new line to the current order
 */
@Component({
    selector: 'app-customer-details',
    standalone: true,
    imports: [CommonModule, FormsModule, MatFormFieldModule, MatAutocompleteModule, MatButtonModule, MatInputModule,
              ButtonContainerComponent, TurboGuiAngularModule],
    providers: [],
    templateUrl: './order-new-line.component.html',
    styleUrls: ['./order-new-line.component.scss']
})


export class OrderNewLineComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "OrderNewLineComponent";
    

    stringUtils = StringUtils;


    isLoadingAutocomplete = false;
    
    
    filteredProducts:any = [];


    delayedMethodCallManager: DelayedMethodCallManager = new DelayedMethodCallManager();
    

    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public apiService: TurboApiCallerService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public userModel: UserModel,
                public ordersNewOrderViewService: OrdersNewOrderViewService) {
       
       super(elementRef, dialogRef);
    }
    
    
    onProductCodeSearchChange(inputValue:string){
        
        this.filteredProducts = [];
        this.ordersNewOrderViewService.orderLineBeingEdited = new OrderLine();
        this.ordersNewOrderViewService.orderLineBeingEdited.product.codigo = inputValue;
        
        if(StringUtils.isEmpty(inputValue) || inputValue.length < 2){
            
            this.delayedMethodCallManager.cancel();
            this.isLoadingAutocomplete = false;
            return;
        }
        
        this.isLoadingAutocomplete = true;
        
        this.delayedMethodCallManager.queue(() =>  {
            
            this.apiService.call('products/products-list', {codeFilter: inputValue}, {busyState: false})
                .then(response => {
                
                if(response.length === 1){
                                                    
                    this.ordersNewOrderViewService.setProductToCurrentLine(response[0].codigo);
                    
                }else{
                    
                    this.filteredProducts = response;
                }                
                
                this.isLoadingAutocomplete = false;
                
            });
        });
    }
    
    
    loadLocalFile(){

        this.dialogService.addDialog(LoadOrderFileComponent, {
                modal: false,
                width: '900px',
                viewContainerRef: this.ordersNewOrderViewService.viewContainerRef
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                   this.cancelLineEdit();
                }
            });
    }
    
    
    onWinkHausClick(){
        
        // TODO - we send 'winkhaus' as a text to prevent a bug with the dialogs hash on the dialogservice class.
        // When release build is generated, the hash is not unique cause the WinkhausComponent class is minified to 't'
        // and the hash is the same as another open dialog, which is not really the same.
        this.dialogService.addDialog(WinkhausComponent,
            {
                width: '800px',
                modal: false,
                texts: ['winkhaus'],
                viewContainerRef: this.ordersNewOrderViewService.viewContainerRef
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    // TODO
                }
            });
    }


    selectColorOrMeasureUnit(){

        const options = [];

        if(this.ordersNewOrderViewService.orderLineBeingEdited.finish !== null &&
           !StringUtils.isEmpty(this.ordersNewOrderViewService.orderLineBeingEdited.finish.grupoVariante)){

            options.push(this.ls.t('SELECT_COLOR', 'centroalum/app'));
        }

        if(!StringUtils.isEmpty(this.ordersNewOrderViewService.orderLineBeingEdited.product.codigo)){

            options.push(this.ls.t('SELECT_MEASURE_UNIT', 'centroalum/app'));
        }

        this.dialogService.addDialog(
            DialogMultipleOptionComponent,
            {
                width:'800px',
                modal: false,
                texts: [this.ls.t('ADD_COLOR_OR_MEASURE_UNIT', 'centroalum/app')],
                options: options
            },
            selection =>{

                if(selection.value === this.ls.t('SELECT_COLOR', 'centroalum/app')){

                    this.showVariantsList();
                }

                if(selection.value === this.ls.t('SELECT_MEASURE_UNIT', 'centroalum/app')){
                    
                     this.showMeasureUnitsList();
                }
            });
    }


    showVariantsList(){

        this.apiService.call('products/variants-list',
            {grupo: this.ordersNewOrderViewService.orderLineBeingEdited.finish.grupoVariante})
            .then(response => {
                    
            this.dialogService.addDialog(
                DialogSingleSelectionListComponent,
                {
                    width:'800px',
                    modal: false,
                    texts: [this.ls.t('SELECT_A_VARIANT', 'centroalum/app'), '', this.ls.t('SEARCH', 'turbodepot/user-interface')],
                    options: response.map((a:any) => a.variante + ' - ' + a.descripcion)
                },
                (selection) =>{
    
                    if(selection.index >= 0){
    
                        this.ordersNewOrderViewService.orderLineBeingEdited.variant = response[selection.index];
                    }
                });
        });
    }


    showMeasureUnitsList(){

        this.apiService.call('products/measure-units-list',
            {producto: this.ordersNewOrderViewService.orderLineBeingEdited.product.codigo})
            .then(response => {
            
            this.dialogService.addDialog(
                DialogSingleSelectionListComponent,
                {
                    width:'800px',
                    modal: false,
                    texts: [this.ls.t('SELECT_MEASURE_UNIT', 'centroalum/app'), '', this.ls.t('SEARCH', 'turbodepot/user-interface')],
                    options: response.map((a:any) => a.code)
                },
                (selection) =>{
    
                    if(selection.index >= 0){
    
                        this.ordersNewOrderViewService.orderLineBeingEdited.product.unidadDeVenta = response[selection.index].code;
                        this.ordersNewOrderViewService.orderLineBeingEdited.unidadesMedida = response[selection.index];
                    }
                });
            
        });
    }

    
    showAccesoriesEditor(){

        this.dialogService.addDialog(
                AccesoriesEditComponent,
                {
                    width: '800px',
                    texts: [this.ls.t('EDIT_ACCESORIES', 'centroalum/app')],
                    viewContainerRef: this.ordersNewOrderViewService.viewContainerRef
                });
    }
    
    
    formatUnits(){
        
        this.ordersNewOrderViewService.orderLineBeingEdited.units = this.ordersNewOrderViewService.orderLineBeingEdited.units ?? 0;
    }
    
    
    onDiscountChange(event:any){
        
        // Format the received discount
        let formattedDiscount = StringUtils.isEmpty(event.target.value) ? '0' : StringUtils.replace(event.target.value, ',', '.');
        
        // Obtain the original discount value to compare with the modified one
        let originalDiscount = this.ordersNewOrderViewService.orderLineBeingEdited.discount.dto;
        
        if(!StringUtils.isEmpty(this.ordersNewOrderViewService.orderLineBeingEdited.isDiscountManuallyModified)){
            
            originalDiscount = this.ordersNewOrderViewService.orderLineBeingEdited.isDiscountManuallyModified.split(';')[0];
        }
        
        originalDiscount = StringUtils.isEmpty(originalDiscount) ? '0' : StringUtils.replace(originalDiscount, ',', '.');
        
        // Check if the discount is manually modified
        if(originalDiscount !== formattedDiscount){
            
            this.ordersNewOrderViewService.orderLineBeingEdited.isDiscountManuallyModified = originalDiscount + ';' + formattedDiscount;
        }
        
        this.ordersNewOrderViewService.orderLineBeingEdited.discount.dto = formattedDiscount;   
    }

    
    cancelLineEdit(){
        
        this.ordersNewOrderViewService.orderLineBeingEdited = new OrderLine();
       
        this.ordersNewOrderViewService.order.total = this.ordersNewOrderViewService.calculateOrderTotal(this.ordersNewOrderViewService.order.orderLines);
        
        this.closeDialog(0);
    }
}
