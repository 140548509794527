import { Injectable } from '@angular/core';
import { ViewService, DialogService, DialogErrorComponent, LocalesService, DialogSingleOptionComponent, NotificationService, GUINotification, ViewsService, TurboApiCallerService } from 'turbogui-angular';
import { SubmitOrderComponent } from './submit-order.component';
import { UserModel } from 'src/main/model/models/user.model';
import { Ruta } from 'src/main/model/dtos/Ruta';
import { AppModel } from 'src/main/model/models/app.model';
import { OrderLine } from 'src/main/model/dtos/OrderLine';
import { NumericUtils, ObjectUtils, StringUtils } from 'turbocommons-ts';
import { Order } from 'src/main/model/dtos/Order';
import { WinkHausOscilo2 } from 'src/main/model/dtos/WinkHausOscilo2';
import { WinkHausPract1 } from 'src/main/model/dtos/WinkHausPract1';
import { WinkHausPract2 } from 'src/main/model/dtos/WinkHausPract2';
import { WinkHausOscilo1 } from 'src/main/model/dtos/WinkHausOscilo1';


/**
 * The service for the new order view
 */
@Injectable()
export class OrdersNewOrderViewService extends ViewService{

    
    /**
     * A reference that must be set by the view parent container so we can propagate its providers and services
     */
    order: Order = new Order();
    
    
    selectedOrderLine = -1;
    
    
    /**
     * Contains the order line that is being actively edited but not still stored to the current order.
     */
    orderLineBeingEdited: OrderLine = new OrderLine();
    
    /**
     * Contains the type of winkhaus tipology that's been selected
     */
    winkHausTipology: null|WinkHausOscilo2|WinkHausPract1|WinkHausPract2|WinkHausOscilo1 = null;
    
    
    winkHausLeftHand: boolean|null = null;
    
    
    winkHausFinish = '';
    
    
    winkHausHeight = '';
    
    
    winkHausWidth = '';


    constructor(public viewsService: ViewsService,
                public ls:LocalesService,
                public dialogService: DialogService,
                public userModel: UserModel,
                public apiService: TurboApiCallerService,
                public notificationService: NotificationService) {
    
        super();
    }
    
    
    /** Get the shipemnt date for an order with articles with no stock */
    private getNoStockDate() {

        let date = new Date();

        if(date.getMonth() === 11) {
            
            date.setFullYear(date.getFullYear() + 1);
            date = new Date(date.getFullYear(), 11, 31);
            
        } else {
            
            date = new Date(date.getFullYear(), 11, 31);
        }

        return this.formatDate(date);
    }
    
    
    /** Get the next date corresponding to an specified day */
    getNextDayDate(day:string, date:Date) {

        let next = true;
        let nextDay = "";

//        if(DateUtils.getDayName(date) === day) {
//            
//            date = DateUtils.dateAdd('date', 1, date);
//        }

        while(next) {

//            nextDay = DateUtils.getDayName(date);

            if(nextDay === day) {
                
                next = false;
                break;
            }

            date.setDate(date.getDate() + 1);
        }

        return date;
    }


    /** Calculate shipment date of the current order */
    calculateShipmentDate(date:Date) {

        //Set shipment date to next year if one product has no stock to be served
        for (let line of this.order.orderLines){
        
            if(line.product.unidadesStock === '0') {
                
                return this.getNoStockDate();
            }
        }

        //If all products are on stock get the date by the shipment route
        // TODO - should ruta be returned with the customer??
        let ruta = new Ruta(); //EntityNavisionRuta(ControlEntities.getEntity([this.order.customer.codigoTransporte], ['codigoTransporte'], EntityNavisionRuta));

        let shipmentDate = new Date(date.getTime());
        let newShipmentDate = new Date(date.getTime());

        if(ruta.periodicidad.toLowerCase().indexOf('diaria') !== -1 || ruta.periodicidad.indexOf('diario') !== -1) {

            shipmentDate.setDate(shipmentDate.getDate() + 1);
        }

        if(ruta.periodicidad.toLowerCase().indexOf('lunes') !== -1) {

            newShipmentDate = this.getNextDayDate(this.ls.t('MONDAY', 'turbodepot/calendar'), date);
//            shipmentDate = shipmentDate === null ? newShipmentDate : shipmentDate;
//            shipmentDate = shipmentDate > newShipmentDate ? newShipmentDate : shipmentDate;
        }

        if(ruta.periodicidad.toLowerCase().indexOf('martes') !== -1) {

            newShipmentDate = this.getNextDayDate(this.ls.t('TUESDAY', 'turbodepot/calendar'), date);
//            shipmentDate = shipmentDate === null ? newShipmentDate : shipmentDate;
//            shipmentDate = shipmentDate > newShipmentDate ? newShipmentDate : shipmentDate;
        }

        if(ruta.periodicidad.toLowerCase().indexOf('miercoles') !== -1) {

            newShipmentDate = this.getNextDayDate(this.ls.t('WEDNESDAY', 'turbodepot/calendar'), date);
//            shipmentDate = shipmentDate === null ? newShipmentDate : shipmentDate;
//            shipmentDate = shipmentDate > newShipmentDate ? newShipmentDate : shipmentDate;
        }

        if(ruta.periodicidad.toLowerCase().indexOf('jueves') !== -1) {

            newShipmentDate = this.getNextDayDate(this.ls.t('THURSDAY', 'turbodepot/calendar'), date);
//            shipmentDate = shipmentDate === null ? newShipmentDate : shipmentDate;
//            shipmentDate = shipmentDate > newShipmentDate ? newShipmentDate : shipmentDate;
        }

        if(ruta.periodicidad.toLowerCase().indexOf('viernes') !== -1) {

            newShipmentDate = this.getNextDayDate(this.ls.t('FRIDAY', 'turbodepot/calendar'), date);
            shipmentDate = shipmentDate > newShipmentDate ? newShipmentDate : shipmentDate;
        }

        this.order.shipmentDate = this.formatDate(shipmentDate);

        return this.order.shipmentDate;

    }
    
    
    private formatDate(date:Date){
        
        let day = date.getDate();
        let month = date.getMonth() + 1;

        return date.getFullYear() + '/' + (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day;
    }
    
    
    setProductToCurrentLine(codigo:string, done: null|(() => void) = null){
        
        let parameters:any = {codigos: [codigo]};
        
        if(!StringUtils.isEmpty(this.order.customer.codigo)){
            
            parameters.cliente = this.order.customer.codigo;
        }
        
        this.apiService.call('products/product-data', parameters).then(response => {
            
            this._addProductDataToLineBeingEdited(response[0]);
            
            if(done !== null){
            
                done();
            }  
        });
    }


    private _addProductDataToLineBeingEdited(productData:any){

        this.orderLineBeingEdited.product = productData.product;
        this.orderLineBeingEdited.finish = productData.finish;
        this.orderLineBeingEdited.unitPrice = Number(productData.unitPrice);
        this.orderLineBeingEdited.unidadesMedida = productData.measureUnits;
        this.orderLineBeingEdited.family = productData.family;
        this.orderLineBeingEdited.subFamily = productData.subFamily;

        for(const accesory of productData.accesories){

            if(accesory.accesorio.indexOf('PO') !== 0 && !NumericUtils.isInteger(accesory.accesorio.substr(0, 1))){

                this.orderLineBeingEdited.accesories.push(ObjectUtils.clone(accesory));
            }
        }

        if(productData.discount !== null){

            this.orderLineBeingEdited.discount = productData.discount;
            this.orderLineBeingEdited.discount.dto = String(this.orderLineBeingEdited.discount.dto).replace(',', '.');
        }

        // If the previous order line has a selected variant and this product contains it on the list of selectable ones, we will automatically select it
        if(this.order.orderLines.length > 0){

            const previousLine = this.order.orderLines[this.order.orderLines.length - 1];

            if (previousLine.variant.variante !== ''){

                for(const variant of productData.variants){

                    if(variant.variante === previousLine.variant.variante){

                          this.orderLineBeingEdited.variant.descripcion = variant.descripcion;
                          this.orderLineBeingEdited.variant.grupo = variant.grupo;
                          this.orderLineBeingEdited.variant.variante = variant.variante;
                          
                          break;
                    }
                }
            }
       }
    }
    
    
    /**
     * Autodetect the CSV format and execute the specific loading process for that CSV data to create order lines
     */
    createOrderLinesFromFile(fileName:string, csvText:string) {
    
        let parameters:any = {
            data: csvText,
            format: StringUtils.getPathExtension(fileName)
        };
        
        if(!StringUtils.isEmpty(this.order.customer.codigo)){
            
            parameters.cliente = this.order.customer.codigo;
        }
        
        this.apiService.call('products/product-data-from-sheet', parameters, {handleErrors:false})
            .then(response => {
                
            if(response.missingProducts.length > 0){
                
                this.dialogService.addDialog(DialogErrorComponent, {
                    id: 'errorProcessingOrderDialog',
                    width:'50vw',
                    maxWidth:'500px',
                    texts: [this.ls.t('FOLLOWING_PRODUCTS_COULDNT_BE_FOUND', 'centroalum/app') + ':',
                            response.missingProducts.join(', ') + '\n\n' + this.ls.t('PLEASE_CONTACT_CENTROALUM', 'centroalum/app')],
                    options: ['Ok']
                });
            }
            
            let linesWithIncompleteInfo = [];
        
            // Create an order line for each one of the received products
            for(let row of response.productsFound){
            
                this.orderLineBeingEdited = new OrderLine();
                
                this.orderLineBeingEdited.units = row.units;
                this._addProductDataToLineBeingEdited(row.productData);
                
                // Assign the variant as specified on the csv
                if(row.variant !== ''){
                    
                    for(let variant of row.productData.variants){
                    
                        if(variant.variante === row.variant){
                            
                            this.orderLineBeingEdited.variant = variant;
                            break;
                        }
                    }  
                
                }

                // Detect if the added line can be saved. if not, a warning must be issued
                if(!this.isSaveLineEnabled()){
                        
                    linesWithIncompleteInfo.push(row.productData.product.descripcion);
                }

                this.addLineBeingEditedToOrder();
            }
            
            // If any incomplete order has been detected, show an alert to the user
            if(linesWithIncompleteInfo.length > 0){
            
                this.dialogService.addDialog(DialogErrorComponent, {
                    id: 'errorProcessingOrderDialog',
                    width:'50vw',
                    maxWidth:'500px',
                    texts: [this.ls.t('ERROR_PROCESSING_ORDER', 'turbodepot/shopping'),
                            this.ls.t('REVIEW_ORDER_PRODUCTS_MISSING_INFO_MSG', 'centroalum/app', [linesWithIncompleteInfo.join('\n')])],
                    options: ['Ok']
                });
            }
            
            this.orderLineBeingEdited = new OrderLine();
            this.order.total = this.calculateOrderTotal(this.order.orderLines); 
        
        }).catch(() => {
                    
            this.dialogService.addDialog(DialogErrorComponent, {
                id: 'errorProcessingOrderDialog',
                width:'50vw',
                maxWidth:'500px',
                texts: [this.ls.t('ERROR_PROCESSING_ORDER', 'turbodepot/shopping'), this.ls.t('PLEASE_CONTACT_CENTROALUM', 'centroalum/app')],
                options: ['Ok']
            });
        });
    }


    /**
     * This method allows us to add a list of products and convert them to lines for the current order
     *
     *@param productsCodeList An array of product codes. Each item will be added as a new line for the current order
     *@param units An integer with the global number of units to add for all products, or an object with key/pair values where key is the product 
     *       code and the value the number of units to add
     *@param ignoreNonExistantProducts If set to true, non existant product codes will be skiped. If set to false, any non existant product code
     *       will cause an exception
     *@param done A callback that will be executed once the loading is complete
     */
    createOrderLinesFromProductsList(productsCodeList: string[], units:any, ignoreNonExistantProducts:boolean, done: null|(() => void) = null){

        let parameters:any = {
            codigos: productsCodeList,
            ignoreNonExistant: ignoreNonExistantProducts
        };
        
        if(this.userModel.isCustomer){
            
            parameters.cliente = this.userModel.customer.codigo;
        }
        
        this.apiService.call('products/product-data', parameters).then(response => {
            
            // Create an order line for each one of the received products
            for(let productData of response){
            
                this.orderLineBeingEdited = new OrderLine();
                
                if(ObjectUtils.isObject(units)){
                    
                    this.orderLineBeingEdited.units = units[
                        StringUtils.replace(productData.product.codigo, '.', '')];
                    
                }else{
                    
                    this.orderLineBeingEdited.units = units;
                }
                
                this._addProductDataToLineBeingEdited(productData);   
                this.addLineBeingEditedToOrder();
            }
            
            this.orderLineBeingEdited = new OrderLine();
            this.order.total = this.calculateOrderTotal(this.order.orderLines);
            
            if(done !== null){
            
                done();
            } 
        });
    }
    
    
    /**
     * Determines if the line that is set currently as being edited has the save button enabled or not.
     */
    isSaveLineEnabled(){

        let mustVariantBeSelected = false;

        if(this.orderLineBeingEdited.finish !== null){

            mustVariantBeSelected = StringUtils.isEmpty(this.orderLineBeingEdited.finish.grupoVariante) ?
                false : this.orderLineBeingEdited.variant.variante === '';
        }

        return this.orderLineBeingEdited.units > 0 &&
            this.orderLineBeingEdited.product.descripcion !== '' &&
            this.orderLineBeingEdited.unidadesMedida.code !== '' &&
            !mustVariantBeSelected;
    }
    
    
    addLineBeingEditedToOrder(){
        
        if(this.selectedOrderLine < 0){
            
            // If the exact same product and accesories were previously saved to an existing line, we will add the units to that line instead of creating a new one
            for(let line of this.order.orderLines) {
    
                if(line.product.codigo == this.orderLineBeingEdited.product.codigo &&
                   line.variant.variante == this.orderLineBeingEdited.variant.variante &&
                   line.accesories.length == this.orderLineBeingEdited.accesories.length) {
    
                    let allAccesoriesEqual:boolean = true;
    
                    for(let i = 0; i < line.accesories.length; i++) {
    
                        if(line.accesories[i].units > 0 &&
                           (line.accesories[i].accesorio != this.orderLineBeingEdited.accesories[i].accesorio ||
                           line.accesories[i].units != this.orderLineBeingEdited.accesories[i].units)) {
        
                            allAccesoriesEqual = false;
                            break;
                        }
                    }
    
                    if(allAccesoriesEqual) {
    
                        line.units = Number(Number(line.units) + Number(this.orderLineBeingEdited.units));                    
                        
                        return;
                    }
                }
            }
            
            this.order.orderLines.push(this.orderLineBeingEdited);
        
        }else{
            
            this.order.orderLines[this.selectedOrderLine] = this.orderLineBeingEdited;
        }
    }
    
    
    /**
     * Calculate the unit price of an order line depending on the applied discounts
     */
    calculateLineUnitPrice(orderLine:OrderLine) {

        if(orderLine.unitPrice <= 0){

            return this.ls.t('NOT_AVAILABLE', 'turbodepot/shopping');
        }

        let unitPrice = orderLine.unitPrice;

        unitPrice = unitPrice - unitPrice * Number(orderLine.discount.dto) / 100;

        return unitPrice.toFixed(2);
    }
    
    
    /**
     * Calculate the discount of an order line
     */
    calculateLineDiscount(line:OrderLine) {

        let disc = (line.isDiscountManuallyModified !== '') ?
            line.isDiscountManuallyModified.split(';')[1] :
            line.discount.dto;

        return StringUtils.replace(String(disc), ',', '.');
    }
    
    
    /**
     * Calculate the unidades de medida of an order line
     */
    calculateLineUnidadesMedida(line:OrderLine) {

        if(line.unidadesMedida.cantidad === undefined){
        
            return 0;                
        }

        return Number(StringUtils.replace(line.unidadesMedida.cantidad, ',', '.'));
    }
    
    
    /**
     * Calculate the total price of an order line
     */
    calculateLineTotalPrice(line:OrderLine) {

        return line.units * (line.unitPrice - line.unitPrice * Number(this.calculateLineDiscount(line)) / 100) * this.calculateLineUnidadesMedida(line);
    }
    
    
    /**
     * Calculate the unit price of an accessory depending on the applied discounts
     */
    calculateAccessoryUnitPrice(accessory:any, line:OrderLine) {

        if(accessory.unitPrice <= 0){

            return this.ls.t('NOT_AVAILABLE', 'turbodepot/shopping');
        }

        let unitPrice = accessory.unitPrice;

        unitPrice = unitPrice - unitPrice * Number(line.discount.dto) / 100;

        return unitPrice.toFixed(2);
    }
    
    
    /**
     * Calculate the unidades de medida of an order accessory
     */
    calculateAccessoryUnidadesMedida(accessory:any) {

        return Number(StringUtils.replace(accessory.measureUnits.cantidad, ',', '.'));
    }
    
    
    /**
     * Calculate the total price of an order accessory
     */
    calculateAccessoryTotalPrice(accessory:any, line:OrderLine) {

        return accessory.units * (accessory.unitPrice - accessory.unitPrice * Number(this.calculateLineDiscount(line)) / 100) * this.calculateAccessoryUnidadesMedida(accessory);
    }
    
    
    calculateOrderTotal(orderLines:any[]) {

        let total = 0;

        for (let l of orderLines) {
        
            total += this.calculateLineTotalPrice(l);
            
            for(let accesory of l.accesories){
                
                let cantidad = Number(StringUtils.replace(accesory.measureUnits.cantidad, ',', '.'));

                total += accesory.units * (accesory.unitPrice - accesory.unitPrice * accesory.discount / 100) * cantidad;        
            }
        }

        return total > 0 ? total.toFixed(2) : '0';
    }
    
    
    sendCurrentOrder(){
        
        let orderDateTime = new Date();
                    
        if(orderDateTime.getHours() > 18){
            
            // Add 1 day to the order date
            orderDateTime.setDate(orderDateTime.getDate() + 1);
        }
        
        this.order.orderDate = this.formatDate(orderDateTime);
        this.order.shipmentDate = this.calculateShipmentDate(orderDateTime);
        
        this.dialogService.addDialog(SubmitOrderComponent,
            {
                width: '800px',
                viewContainerRef: this.viewContainerRef
            }, 
            (selection) =>{
            
                if(selection.index === 1){
                    
                    this.order.isUserACustomer = this.userModel.isCustomer;
                    this.order.userRelatedComercial = this.userModel.relatedComercial;
       
                    this.apiService.call('orders/submit-order', { orders: JSON.stringify([this.order]) })
                        .then(() => {
                        
                        this.dialogService.addDialog(DialogSingleOptionComponent, {
                            width: '400px',
                            texts: [this.ls.t('ORDER_CORRECTLY_SENT', 'turbodepot/shopping')],
                            options: [this.ls.t('ACCEPT', 'turbodepot/user-interface')]
                        },() =>{
                            
                            this.notificationService.send(new GUINotification(AppModel.SHOW_ORDERS_OPTIONS));
                        });
                    });
                }
           });
    }
}
