/**
 * SubFamilia entity
 */
export class SubFamilia {

    familia = '';


    subFamilia = '';


    descripcion = '';
}
