/**
 * Customer entity
 */
export class Customer {

    codigo = '';

    nombre = '';

    alias = '';

    direccion = '';
        
    direccionesEnvio = [];
    
    direccionesEnvioDetalle = [];

    cp = '';

    poblacion = '';

    comercial = '';

    telefono = '';

    telefono2 = '';

    telefono3 = '';

    contacto = '';

    contacto2 = '';

    contacto3 = '';

    fax = '';

    mail = '';

    formaDePago = '';

    terminosDePago = '';

    codigoTransporte = '';

    referenciaCliente = '';
    
    tieneControl = '';
}