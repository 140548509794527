<img class="homeLogo" src="./assets/icons/centroalum-logo2.svg" />

<div class="shortcutsContainer">

    <tg-button-container id="productsButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".8"
        [hoverOpacity]=".9"
        [clickOpacity]="1"
        [clickScale]="1.01"
        (click)="viewsService.pushView(productsViewComponent)">
        
        <div>            
            <h2>{{ls.tAllUpperCase('PRODUCTS', 'turbodepot/shopping')}}</h2>
        </div>
                
    </tg-button-container>

    <tg-button-container id="catalogsButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".8"
        [hoverOpacity]=".9"
        [clickOpacity]="1"
        [clickScale]="1.01"
        (click)="viewsService.pushView(catalogsViewComponent)">
        
        <div> 
            <h2>{{ls.tAllUpperCase('CATALOGS', 'turbodepot/shopping')}}</h2>
        </div>
        
    </tg-button-container>
    
    <div>
        <tg-button-container id="ordersButton"
            [releaseOnMouseUp]="false"
            [defaultOpacity]=".8"
            [hoverOpacity]=".9"
            [clickOpacity]="1"
            [clickScale]="1.01"
            *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS)"
            (click)="viewsService.pushView(ordersViewComponent)">
            
            <div> 
                <h2>{{ls.tAllUpperCase('ORDERS', 'turbodepot/shopping') + ' / ' + ls.tAllUpperCase('ESTIMATES', 'turbodepot/shopping')}}</h2>
            </div> 
            
        </tg-button-container>
        
        <tg-button-container id="pergolasButton"
            [releaseOnMouseUp]="false"
            [defaultOpacity]=".8"
            [hoverOpacity]=".9"
            [clickOpacity]="1"
            [clickScale]="1.01"
            *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR)"
            (click)="browserService.goToUrl('https://centroalum.uniapp.no/', true)">
            
            <div> 
                <h2>{{ls.tAllUpperCase('CONFIG_PERGOLAS_VENECIANAS', 'centroalum/app')}}</h2>
            </div> 
            
        </tg-button-container>
        
        <tg-button-container id="windowReportButton"
            [releaseOnMouseUp]="false"
            [defaultOpacity]=".8"
            [hoverOpacity]=".9"
            [clickOpacity]="1"
            [clickScale]="1.01"
            (click)="browserService.goToUrl('https://transmitancia.unitest.site/', true)">
            
            <div> 
                <h2>{{ls.tAllUpperCase('WINDOW_REPORT', 'centroalum/app')}}</h2>
            </div> 
            
        </tg-button-container>
    </div>
    
</div>


<div class="footerBand">
    
</div>
