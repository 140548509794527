<h3>{{ls.t('SEND_ORDER', 'turbodepot/shopping') + '?'}}</h3>

<p>{{ls.t('CONFIRM_SENDING_CURRENT_ORDER', 'centroalum/app') + ' ' + ordersNewOrderViewService.order.total + '€'}}</p>

<h4>{{ls.t('ORDER_DATE', 'turbodepot/shopping')}}: {{ ordersNewOrderViewService.order.orderDate }}</h4>
<h4>
    {{ls.t('SHIPPING_DATE', 'turbodepot/shopping')}}: {{ ordersNewOrderViewService.order.shipmentDate }}
    <span id="selectShippingDateLink" (click)="selectShippingDate()">{{ls.t('MODIFY', 'turbodepot/user-interface')}}</span>
</h4>

<mat-form-field *ngIf="ordersNewOrderViewService.order.customer.direccionesEnvio.length > 1">
            
    <mat-label>{{this.ls.t('SHIPPING_ADDRESS', 'turbodepot/shopping')}}</mat-label>
    
    <mat-select id="selectShippingAddressInput"
        [(ngModel)]="ordersNewOrderViewService.order.shippingAddress">
    
        <mat-option *ngFor="let value of ordersNewOrderViewService.order.customer.direccionesEnvio; let i = index" [value]="value">
          {{ ordersNewOrderViewService.order.customer.direccionesEnvioDetalle[i] }}
        </mat-option>
                    
    </mat-select>
  
</mat-form-field>

<mat-form-field>
    <mat-label>{{ls.t('ORDER_REFERENCE', 'centroalum/app')}}</mat-label>
    <input matInput autoSelectTextOnFocus
        [(ngModel)]="ordersNewOrderViewService.order.referenciaPedido">
</mat-form-field>

<mat-form-field>
    
    <mat-label>{{ls.t('COMMENTS', 'turbodepot/user-interface')}}</mat-label>
    
    <input matInput autoSelectTextOnFocus
            maxlength="60"
            [(ngModel)]="ordersNewOrderViewService.order.comment">
    
</mat-form-field>

<mat-checkbox id="requestOfferNoOrderCheckBox" name="requestOfferNoOrderCheckBox"
    [(ngModel)]="ordersNewOrderViewService.order.isOrderAnOffer">

    {{ls.tAllUpperCase('REQUEST_OFFER_INSTEAD_OF_ORDER', 'centroalum/app')}}
    
</mat-checkbox>


<div class="submitButtonsContainer">
    
    <button id="cancelLineButton" mat-button
        (click)="closeDialog(0)">
        
        {{ls.t('CANCEL', 'turbodepot/user-interface')}}
        
    </button>
    
    <button id="addLineButton" mat-raised-button color="primary"
        (click)="closeDialog(1)">
        
        <div>
            <img src="./assets/icons/truck.svg" />    
            <p>{{ls.tAllUpperCase('SEND_ORDER', 'turbodepot/shopping')}}</p>
        </div>
            
    </button>

</div>