<div class="shortcutsContainer">

    <tg-button-container id="newOrderButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        (click)="loadOrdersView()">
        
        <img src="./assets/icons/add.svg" />
        <h2>{{ls.t('NEW_ORDER', 'turbodepot/shopping')}}</h2>
        
    </tg-button-container>

    <tg-button-container id="listWebOrdersButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        (click)="loadWebListView()">
        
        <img src="./assets/icons/orders.svg" />
        <h2>{{ls.t('WEB_ORDERS', 'centroalum/app')}}</h2>
        
    </tg-button-container>
    
    <tg-button-container id="listOrdersHistoryButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        (click)="loadHistoryView()">
        
        <img src="./assets/icons/orders.svg" />
        <h2>{{ls.t('ORDERS_HISTORY', 'centroalum/app')}}</h2>
        
    </tg-button-container>    
    
    <tg-button-container id="expansionRoutesButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_EXPANSION_ROUTES)"
        (click)="loadExpansionRoutesView()">
        
        <img src="./assets/icons/orders.svg" />
        <h2>{{ls.t('EXPANSION', 'centroalum/app')}}</h2>
        
    </tg-button-container>

</div>
