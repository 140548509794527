/**
 * Familia entity
 */
export class Familia {

    codigo = '';


    descripcion = '';
}
