/**
 * Variante entity
 */
export class Variante {

    grupo = '';


    variante = '';


    descripcion = '';
}
