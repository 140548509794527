<h2>{{ls.t('NEW_USER_REGISTER_REQUEST', 'turbodepot/users')}}</h2>
<tg-button-image id="closeNewUserButton"
    src="./assets/icons/logout.svg"
    [defaultOpacity]=".3"
    [hoverOpacity]=".6"
    [clickOpacity]="1"
    [clickScale]=".7"
    (mousedown)="this.closeDialog(0);">
</tg-button-image>

<div class="inputsContainer">
    
    <form [formGroup]="form">
        
        <div>
            <mat-form-field>
                <mat-label>{{ls.t('NAME', 'turbodepot/personal-data')}}</mat-label>
                <input id="newNameInput" matInput formControlName="name" maxlength="50" autoFocusOnDisplay
                    [(ngModel)]="userCustomFields.name">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>{{ls.t('SURNAMES', 'turbodepot/personal-data')}}</mat-label>
                <input id="newSurNamesInput" matInput formControlName="surnames" maxlength="100"
                    [(ngModel)]="userCustomFields.surnames">
            </mat-form-field>
        </div>
        
        <div>
            <mat-form-field>
                <mat-label>{{ls.t('EMAIL', 'turbodepot/personal-data')}}</mat-label>
                <input id="newEmailInput" matInput formControlName="email" maxlength="254"
                    [(ngModel)]="userObject.userName">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>{{ls.t('PHONE', 'turbodepot/personal-data')}}</mat-label>
                <input id="newPhoneInput" matInput formControlName="phone" maxlength="15"
                    [(ngModel)]="userCustomFields.phone">
            </mat-form-field>
        </div>
        
        <div>
            <mat-form-field>
                <mat-label>{{ls.t('COMPANY', 'turbodepot/shopping')}}</mat-label>
                <input id="newCompanyInput" matInput formControlName="company" maxlength="100"
                    [(ngModel)]="userCustomFields.company">
            </mat-form-field>
            
            <mat-form-field>
            
                <mat-label>{{this.ls.t('OCCUPATION', 'turbodepot/personal-data')}}</mat-label>
                
                <mat-select id="newOccupationInput" formControlName="occupation"
                    [(ngModel)]="userCustomFields.occupation"
                    (selectionChange)="onOccupationChangeEvent($event.value)">
                
                    <mat-option *ngFor="let value of occupationValues" [value]="value.value">
                      {{ value.value }}
                    </mat-option>
                                
                </mat-select>
              
            </mat-form-field>
            
        </div>
        
        <mat-form-field>
            <mat-label>{{ls.t('ADDRESS', 'turbodepot/personal-data')}}</mat-label>
            <input id="newAddressInput" matInput formControlName="address" maxlength="200"
                [(ngModel)]="userCustomFields.address">
        </mat-form-field>
        
        
        <div>
            <mat-form-field>
                <mat-label>{{ls.t('CITY', 'turbodepot/personal-data')}}</mat-label>
                <input id="newCityInput" matInput formControlName="city" maxlength="50"
                    [(ngModel)]="userCustomFields.city">
            </mat-form-field>
        
            <mat-form-field>
                <mat-label>{{ls.t('DISTRICT', 'turbodepot/personal-data')}}</mat-label>
                <input id="newDistrictInput" matInput formControlName="district" maxlength="50"
                    [(ngModel)]="userCustomFields.district">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>{{ls.t('POSTAL_CODE', 'turbodepot/personal-data')}}</mat-label>
                <input id="newPostalCodeInput" matInput formControlName="postalCode" maxlength="10"
                    [(ngModel)]="userCustomFields.postalCode">
            </mat-form-field>
        </div>
    
    </form>
    
</div>

<button id="createNewUserButton" mat-raised-button color="primary"
    [disabled]="form.invalid"
    (click)="createUser()">
            
    {{ls.t('CREATE_USER', 'turbodepot/users')}}
    
</button>