import { Component, ElementRef, Inject } from '@angular/core';
import { LocalesService, DialogBaseComponent, ViewsService, DialogService, DialogSingleInputComponent, TurboApiCallerService } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';


/**
 * Component to show the details of an order
 */
@Component({
      selector: 'app-order-history-details',
      standalone: true,
    imports: [CommonModule, MatTooltipModule, MatButtonModule],
    providers: [],
      templateUrl: './order-history-details.component.html',
      styleUrls: ['./order-history-details.component.scss']
})


export class OrderHistoryDetailsComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "OrderHistoryDetailsComponent";
    
    
    orderObservations = '';
    

    constructor(public elementRef: ElementRef,
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public apiService: TurboApiCallerService,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    
        super(elementRef, dialogRef);               
    }
    
    
    isGreaterThanZero(value:string){
        
        return Number(value) > 0;
    }
    
    
    editObservations(){
        
        let dialogTitle = this.ls.t('OBSERVATIONS', 'turbodepot/user-interface') + ' ' + this.expansionRoutesViewService.currentCustomerName;
        
        this.dialogService.addDialog(
            DialogSingleInputComponent,
            {
                width:'90%',
                maxWidth: '1200px',
                modal: false,
                texts: [dialogTitle, '', this.ls.t('OBSERVATIONS', 'turbodepot/user-interface'), this.expansionRoutesViewService.currentOrderComments],
                options: [this.ls.t('SAVE', 'turbodepot/user-interface')]
            }, 
            (selection) =>{
                
                if(selection.index === 0){
                    
                    this.apiService.call('expansion/order-comment-save',
                            {orderId: this.expansionRoutesViewService.currentOrderNumber, comment: selection.value })
                            .then(response => {
                                    
                                if(response === true){

                                    this.expansionRoutesViewService.currentOrderComments = selection.value;
                                    this.expansionRoutesViewService.loadRouteOrders();                        
                                }
                            });
                }                 
            });
    }
}
