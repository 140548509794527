<h3>{{isTipoSelected() ? this.ls.t('WINKHAUS_SIZES', 'centroalum/app') : this.ls.t('WINKHAUS_TYPOLOGY', 'centroalum/app')}}</h3>

<div class="mainContainer"
    [style.max-height]="getItemsContainerMaxheight()">

    <div class="firstStepContainer" *ngIf="!isTipoSelected()" [@fadeIn]>
    
        <img id="winkHausOscilo1Button" src="./assets/orders-new-order-view/Oscilo1.png"
            (click)="selectWinkHausOscilo1()" />
        
        <img id="winkHausOscilo2Button" src="./assets/orders-new-order-view/Oscilo2.png"
            (click)="selectWinkHausOscilo2()" />
        
        <img id="winkHausPract1Button" src="./assets/orders-new-order-view/Pract1.png"
            (click)="selectWinkHausPract1()" />
        
        <img id="winkHausPract2Button" src="./assets/orders-new-order-view/Pract2.png"
            (click)="selectWinkHausPract2()" />
        
    </div>
    
    
    <div class="secondStepContainer" *ngIf="isTipoSelected()" [@fadeIn]>
    
        <hr>
    
        <h4>{{this.ls.t('HAND', 'turbodepot/personal-data')}}</h4>
    
        <mat-radio-group (change)="ordersNewOrderViewService.winkHausLeftHand = $event.value">
        
            <mat-radio-button id="winkHausLeftRadioButton" [value]="true">
                {{this.ls.t('LEFT', 'turbodepot/user-interface')}}</mat-radio-button>
            
            <mat-radio-button id="winkHausRightRadioButton" [value]="false">
                {{this.ls.t('RIGHT', 'turbodepot/user-interface')}}</mat-radio-button>
            
        </mat-radio-group>
        
        <hr>
        
        <mat-form-field>
        
            <mat-label>{{this.ls.t('FINISH', 'turbodepot/shopping')}}</mat-label>
            
            <mat-select id="winkHausFinishSelector" [(value)]="ordersNewOrderViewService.winkHausFinish">
            
                <mat-option [value]="10">
                    PLATA
                </mat-option>
                
                <mat-option [value]="30">
                    BLANCO
                </mat-option>
                
                <mat-option [value]="31">
                    NEGRO
                </mat-option>
                
                <mat-option [value]="71">
                    INOX
                </mat-option>
                            
            </mat-select>
          
        </mat-form-field>
        
        <mat-form-field>
        
            <mat-label>{{this.ls.t('MARK_HEIGHT', 'centroalum/app')}}</mat-label>
            
            <mat-select id="winkHausHeightSelector" [(value)]="ordersNewOrderViewService.winkHausHeight">
            
                <mat-option *ngFor="let line of ordersNewOrderViewService.winkHausTipology.heightSizes; let i = index"
                    [value]="line">
                    {{line}}
                </mat-option>
            
            </mat-select>
          
        </mat-form-field>
        
        <mat-form-field *ngIf="ordersNewOrderViewService.winkHausTipology.widthSizes.length > 0">
        
            <mat-label>{{this.ls.t('MARK_WIDTH', 'centroalum/app')}}</mat-label>
            
            <mat-select id="winkHausWidthSelector" [(value)]="ordersNewOrderViewService.winkHausWidth">
            
                <mat-option *ngFor="let line of ordersNewOrderViewService.winkHausTipology.widthSizes; let i = index"
                    [value]="line">
                    {{line}}
                </mat-option>
            
            </mat-select>
          
        </mat-form-field>
                        
        <mat-form-field>
            <mat-label>{{ls.t('UNITS', 'turbodepot/shopping')}}</mat-label>
            <input id="winkhausUnits" type="number" matInput autoSelectTextOnFocus
                autocomplete="off"
                [(ngModel)]="ordersNewOrderViewService.orderLineBeingEdited.units">
        </mat-form-field>
        
        <hr>
        
        <div class="buttonsContainer">
                   
            <button id="cancelWinkhausButton" mat-button
                (click)="closeDialog(0)">
                
                {{this.ls.t('CANCEL', 'turbodepot/user-interface')}}
                
            </button>
            
            <button id="saveWinkhausButton" mat-raised-button color="primary"
                [disabled]="!isSaveButtonEnabled()"
                (click)="submitWinkHaus()">
                
                {{this.ls.t('SAVE', 'turbodepot/user-interface')}}
                
            </button>
            
        </div>
    
    </div>
    
</div>
