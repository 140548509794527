<h2>{{ls.t('ORDER_LINE', 'turbodepot/shopping')}}</h2>

<mat-form-field class="searchProductCodeContainer">
    <mat-label>{{ls.t('PRODUCT_CODE', 'turbodepot/shopping')}}</mat-label>
    <input id="searchProductCode" matInput autoFocusOnDisplay autoSelectTextOnFocus
        [matAutocomplete]="auto"
        [(ngModel)]="ordersNewOrderViewService.orderLineBeingEdited.product.codigo"
        (input)="onProductCodeSearchChange($event.target.value)">
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete"
    (optionSelected)="ordersNewOrderViewService.setProductToCurrentLine($event.option.value)">

    <mat-option *ngFor="let filteredProduct of filteredProducts" [value]="filteredProduct.codigo">
        <span class="autocompleteCode">{{filteredProduct.codigo}}</span> -
        <span class="autocompleteName">{{filteredProduct.descripcion}}</span>
    </mat-option>
  
</mat-autocomplete>

 <tg-button-container id="loadLocalFileNewLineButton"
    [defaultOpacity]=".7"
    [hoverOpacity]=".8"
    [clickOpacity]="1"
    [clickScale]="1.07"
    *ngIf="ordersNewOrderViewService.orderLineBeingEdited.product.descripcion === ''"
    (click)="loadLocalFile()">
    
    <img src="./assets/icons/upload.svg" />

</tg-button-container>

<img id="winkHausButton"
    src="./assets/orders-new-order-view/winkHausButton.png"
    *ngIf="ordersNewOrderViewService.orderLineBeingEdited.product.descripcion === ''"
    (click)="onWinkHausClick()" />

<img class="loadingIcon" src="./assets/icons/loading-animation.svg"
    *ngIf="isLoadingAutocomplete" />
    
<div *ngIf="ordersNewOrderViewService.orderLineBeingEdited.product.descripcion !== ''">

    <h3>
        {{this.ls.t('PRODUCT', 'turbodepot/shopping')}}: {{ordersNewOrderViewService.orderLineBeingEdited.product.descripcion}}
    </h3>
      
    <tg-button-container id="addColorMeasureUnitButton"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.01"
        *ngIf="(!stringUtils.isEmpty(ordersNewOrderViewService.orderLineBeingEdited.product.unidadDeVenta) ||
                !stringUtils.isEmpty(ordersNewOrderViewService.orderLineBeingEdited.finish.grupoVariante)) &&
                !stringUtils.isEmpty(ordersNewOrderViewService.orderLineBeingEdited.product.codigo)"
        (click)="selectColorOrMeasureUnit()">
        
        <img src="./assets/icons/add.svg" />

    </tg-button-container>
          
    <p>{{this.ls.t('FINISH', 'turbodepot/shopping')}}: {{ordersNewOrderViewService.orderLineBeingEdited.finish === null? '' : ordersNewOrderViewService.orderLineBeingEdited.finish.descripcion}}</p>
    
    <p *ngIf="!stringUtils.isEmpty(ordersNewOrderViewService.orderLineBeingEdited.variant.descripcion)">
        {{this.ls.t('VARIANT', 'centroalum/app')}}: {{ordersNewOrderViewService.orderLineBeingEdited.variant.variante + ' - ' + ordersNewOrderViewService.orderLineBeingEdited.variant.descripcion}}</p>

    <p *ngIf="!stringUtils.isEmpty(ordersNewOrderViewService.orderLineBeingEdited.unidadesMedida.code)">
        {{this.ls.t('MEASURE_UNIT', 'centroalum/app')}}: {{ordersNewOrderViewService.orderLineBeingEdited.unidadesMedida.code}}</p>
    
    <p>{{this.ls.t('PACKAGING', 'turbodepot/shopping')}}: {{ordersNewOrderViewService.orderLineBeingEdited.product.empaquetado}}</p>
    
    <p>{{this.ls.t('UNIT_PRICE', 'turbodepot/shopping')}}: {{ordersNewOrderViewService.calculateLineUnitPrice(ordersNewOrderViewService.orderLineBeingEdited)}}</p>
    
    <tg-button-container id="editAccesoriesButton"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.04"
        *ngIf="ordersNewOrderViewService.orderLineBeingEdited.accesories.length > 0"
        (click)="showAccesoriesEditor()">
        
        <h4>{{this.ls.t('EDIT_ACCESORIES', 'centroalum/app')}}</h4>
        
        <ng-container *ngFor="let accesory of ordersNewOrderViewService.orderLineBeingEdited.accesories">
            <p *ngIf="accesory.units > 0">{{accesory.accesorio}} - {{accesory.descripcion}} ({{accesory.units}})</p>
        </ng-container>

    </tg-button-container>
        
    <div class="unitsDiscountContainer">
        <mat-form-field>
            <mat-label>{{ls.t('UNITS', 'turbodepot/shopping')}}</mat-label>
            <input id="productUnits" type="number" matInput autoFocusOnDisplay autoSelectTextOnFocus
                autocomplete="off"
                (change)="formatUnits()"
                [(ngModel)]="ordersNewOrderViewService.orderLineBeingEdited.units">
        </mat-form-field>
        
        <mat-form-field *ngIf="!userModel.isCustomer">
            <mat-label>{{ls.t('DISCOUNT', 'turbodepot/shopping')}}</mat-label>
            <input id="productDiscount" type="number" matInput autoSelectTextOnFocus
                autocomplete="off"
                (change)="onDiscountChange($event)"
                [ngModel]="ordersNewOrderViewService.orderLineBeingEdited.discount.dto">
        </mat-form-field>
    </div>
        
</div>

<button id="addLineButton" mat-raised-button color="primary"
    [disabled]="!ordersNewOrderViewService.isSaveLineEnabled()"
    (click)="ordersNewOrderViewService.addLineBeingEditedToOrder(); cancelLineEdit();">
    
    {{this.ls.t('SAVE_LINE', 'turbodepot/shopping')}}
    
</button>

<button id="cancelLineButton" mat-button
    (click)="cancelLineEdit()">
    
    {{this.ls.t('CANCEL', 'turbodepot/user-interface')}}
    
</button>