import { Component, Inject, ElementRef } from '@angular/core';
import { LocalesService, DialogBaseComponent, ViewsService, DialogTwoOptionComponent, DialogService, TurboGuiAngularModule, TurboApiCallerService} from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ExpeditionEditComponent } from './expedition-edit.component';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';


/**
 * Expeditions list component
 */
@Component({
    selector: 'app-expeditions-list',
    standalone: true,
    imports: [CommonModule, FormsModule, MatInputModule, MatButtonModule, MatTooltipModule, TurboGuiAngularModule],
    providers: [],
    templateUrl: './expeditions-list.component.html',
    styleUrls: ['./expeditions-list.component.scss']
})


export class ExpeditionsListComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "ExpeditionsListComponent";
    
    
    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                public apiService: TurboApiCallerService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
    }
    
    
    setExpeditionAsFilter(expedition:any){
        
        this.expansionRoutesViewService.routeDetailsExpeditionFilter = expedition;
        this.expansionRoutesViewService.loadRouteOrders();
        this.closeDialog(1);
    }
    
    
    createNewExpedition(){
    
        this.expansionRoutesViewService.isEditingExpedition = false;
        this.expansionRoutesViewService.expeditionBeingCurrentlyEdited = {
            name: '',
            date: '',
            orders: '',
            routeId: this.expansionRoutesViewService.routeBeingCurrentlyEdited.dbId,
        };
        
        this.dialogService.addDialog(
            ExpeditionEditComponent,
            {
            width: '1000px',
            viewContainerRef: this.expansionRoutesViewService.viewContainerRef
        });
    }
    
    
    editExpedition(expedition:any){
        
        this.expansionRoutesViewService.isEditingExpedition = true;
        this.expansionRoutesViewService.expeditionBeingCurrentlyEdited = expedition;
        
        this.dialogService.addDialog(
        ExpeditionEditComponent,
        {
            width: '1000px',
            viewContainerRef: this.expansionRoutesViewService.viewContainerRef
        });
    }
    
    
    deleteExpedition(expedition:any){
            
        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.t('DELETE_EXPEDITION', 'centroalum/app') + '?', this.ls.t('CONFIRM_DELETE_EXPEDITION', 'centroalum/app', [expedition.name])],
                options: [this.ls.t('DELETE_EXPEDITION', 'centroalum/app'), this.ls.t('CANCEL', 'turbodepot/user-interface')]
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    this.apiService.call('expansion/expedition-delete',
                        {expeditionId: expedition.dbId})
                        .then(response => {
                                
                            if(response === true){
                                
                                this.expansionRoutesViewService.routeDetailsExpeditionFilter = null;
                                this.expansionRoutesViewService.loadExpeditions();
                                this.expansionRoutesViewService.loadRouteOrders();
                            }
                        });
                }
            });
    }
}
