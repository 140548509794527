/**
 * Contains definitions and data related to WinkHaus Pract 2 tipology
 */
export class WinkHausPract2 {

    /** List of available heights for the tipology */
    heightSizes = ["606-895", "896-1145", "1146-1495", "1496-1655", "1656-1895", "1896-2395"];


    /** List of available widths for the tipology */
    widthSizes = [];


    /** List of values that match each height/width for the right hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    rightValues = ["HM860808+HM960809", "HM860108+HM960109", "HM860148+HM960149", "HM860158+HM960159", "HM860188+HM960189", "HM860238+HM960239"];


    /** List of values that match each height/width for the left hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    leftValues = ["HM860809+HM960808", "HM860109+HM960108", "HM860149+HM960148", "HM860159+HM960158", "HM860189+HM960188", "HM860239+HM960238"];


    /** list of codes for finishes products that must be added to the final product line when right hand is selected */
    rightAdd = ["HE3638XX", "CI4004XX", "HE3639XX"];


    /** list of codes for finishes products that must be added to the final product line when left hand is selected */
    leftAdd = ["HE3638XX", "CI4004XX", "HE3639XX"];
}
