/**
 * Descuento entity
 */
export class Descuento {


    cliente = '';


    familia = '';


    subFamilia = '';


    acabado = '';


    serieDto = '';


    producto = '';


    dto = '0';


    fechaModif = '';
}
