import { Injectable } from '@angular/core';
import { ViewsService, TurboApiCallerService, DialogService, ViewService } from 'turbogui-angular';
import { UserModel } from 'src/main/model/models/user.model';
import { OrderHistoryDetailsComponent } from './order-history-details.component';
import { CustomerService } from '../../../controller/customer.service';


/**
 * The service for the orders list view
 */
@Injectable()
export class OrdersHistoryViewService extends ViewService {
    
    
    /**
     * The customer code for the one we will be listing the order history
     */
    currentCustomer = '';
    
    
    /**
 * The customer name for the one we will be listing the order history
     */
    currentCustomerNombre = '';
    
    
    /**
     * List of order items that have been loaded for the current user
     */
    itemsList: any[] = [];
    
    
    /**
     * Data for the order that is being currently shown
     */
    currentOrderData: any = null;
    

    constructor(public viewsService: ViewsService,
                public dialogService: DialogService,
                public customerService: CustomerService,
                public userModel: UserModel,
                public apiService: TurboApiCallerService) {
    
        super();
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadList() {

        this.itemsList = [];
        
        this.apiService.call('orders/list-navision-orders', {"codigo-cliente": this.currentCustomer})
            .then(response => {
            
            this.itemsList = response;
        });
    }
    
    
    /**
     * Search for a customer to get the list 
     */
    selectCustomer() {

        this.customerService.selectCustomer((codigo, nombre) => {
                        
            if(codigo != ''){
                
                this.currentCustomer = codigo;
                this.currentCustomerNombre = nombre;
                this.loadList();
            }
        });
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadOrderData(orderNumber:string) {

        this.currentOrderData = null;
        
        this.apiService.call('orders/get-navision-order-details',
            {orderNumber: orderNumber, "codigo-cliente": this.currentCustomer})
            .then(response => {
                    
            this.currentOrderData = response;
                        
            this.dialogService.addDialog(OrderHistoryDetailsComponent, {
                width: '1500px',
                modal: false,
                viewContainerRef: this.viewContainerRef});
        });
    }
}
