<div class="mainContainer">

    <h3>{{this.ls.t('CUSTOMER', 'turbodepot/shopping') + ': ' + 
        expansionRoutesViewService.currentCustomerCode + ' - ' + expansionRoutesViewService.currentCustomerName + ' - ' + expansionRoutesViewService.currentOrderNumber }}
    </h3>
    
    <div class="lineContainer">
        <h4>{{this.ls.t('REFERENCE', 'turbodepot/shopping')}}</h4>
        <h4>{{this.ls.t('VARIANT', 'centroalum/app')}}</h4>
        <h4>{{this.ls.t('AMOUNT', 'turbodepot/shopping')}}</h4>
        <h4>{{this.ls.t('PENDING_AMOUNT', 'centroalum/app')}}</h4>
        <h4>{{this.ls.t('SHIPPED_AMOUNT', 'centroalum/app')}}</h4>
        <h4>{{this.ls.t('PENDING_TO_RECEIVE', 'centroalum/app')}}</h4>
        <h4>{{this.ls.t('DELIVERY_DATE', 'centroalum/app')}}</h4>
        <h4>{{this.ls.t('ESTIMATE_DATE', 'centroalum/app')}}</h4>
        <h4>{{this.ls.t('ORDER_DATE', 'turbodepot/shopping')}}</h4>
    </div>
    
    <div class="lineContainer"
        [class.hasPendingToReceive]="isGreaterThanZero(line[8])"
        *ngFor="let line of expansionRoutesViewService.currentOrderData">
        
        <p>{{line[0]}}</p>
        <p>{{line[5]}}</p>
        <p>{{line[1]}}</p>
        <p>{{line[2]}}</p>
        <p>{{line[6]}}</p>
        <p>{{line[8]}}</p>
        <p>{{line[3]}}</p>
        <p>{{line[7]}}</p>
        <p>{{line[4]}}</p>
    </div>
</div>

<div class="bottomContainer">
    
    <div>
        <h5 (click)="editObservations()">
            <img id="editOrderobservationsButton" src="./assets/icons/edit.svg"
                matTooltip="{{ls.t('OBSERVATIONS', 'turbodepot/user-interface')}}"
                (click)="closeDialog(1)"/>
                
            {{ls.t('OBSERVATIONS', 'turbodepot/user-interface')}}
        </h5>
        
        <p>
            {{expansionRoutesViewService.currentOrderComments}}
        </p>
    </div>
    
    <button id="confirmCustomerButton" mat-raised-button color="primary"
        (click)="closeDialog(1)">
        
        {{this.ls.t('CLOSE', 'turbodepot/user-interface')}}
    </button>
    
</div>