import { Injectable } from '@angular/core';


/**
 * Model containing all the application configurable parameters
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationModel {


    /**
     * An url that points to the backend server entry point root
     * Notice that this is replaced with the right value on build and release via the turbobuilder.json wildcards feature
     */
    serverRootUrl = 'https://api.centroalum.com/_dev/api';

    
    /**
     * The url for the official centroalum website
     */
    centroalumOfficialWebSiteUrl = 'https://centroalum.es';


    /**
     * List with the application locales
     */
    locales = ['es_ES'];
}
