import { Component, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsService, View, LocalesService, BrowserService, ButtonImageComponent, ButtonContainerComponent } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { CatalogsViewService } from 'src/main/view/views/catalogs-view/catalogs-view.service';
import { SubscriptionLike } from 'rxjs';


/**
 * Catalogs view
 */
@Component({
    selector: 'app-catalogs-view',
    standalone: true,
    imports: [CommonModule, ButtonImageComponent, ButtonContainerComponent],
    providers: [CatalogsViewService],
    templateUrl: './catalogs-view.component.html',
    animations: [ContentFadeAnimationClass.getTrigger()],
    styleUrls: ['./catalogs-view.component.scss']
})


export class CatalogsViewComponent extends View implements AfterViewInit, OnDestroy {


    /**
     * Subscription to location
     */
    private browserSubscription: SubscriptionLike;


    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public catalogsViewService: CatalogsViewService,
                public browserService: BrowserService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, catalogsViewService);

        this.browserService.setCurrentUrlURI('');
    }
    
 
    ngAfterViewInit() {

        this.browserSubscription = this.browserService.subscribeToUrlChange((value) => {

            let urlId = (value.url as string).split('/').pop();

            if (urlId !== this.catalogsViewService.currentItemId) {

                this.catalogsViewService.load(urlId);
            }
        });
        
        this.catalogsViewService.load();
    }


    /**
     * Process the catalog items click
     */
    onCatalogItemClick(item: any) {

        this.browserService.setCurrentUrlURI(item.id);

        this.catalogsViewService.load(item.id, item.isDirectory);
    }


    /**
     * Remove subscriptions on component removal
     */
    ngOnDestroy() {

        this.browserService.setCurrentUrlURI('');
        this.browserSubscription.unsubscribe();
    }
}
